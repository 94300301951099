// * Angular
import { Pipe, PipeTransform } from '@angular/core';
/*
note:
    instal npm install typings -g --save-dev
    npm install @types/node --save-dev
    in tsconfig.app.json add the next line in "compilerOptions"
    "compilerOptions":{
        "types": [ "node" ],
    }
},
*/

@Pipe({
    name: 'translate',
    standalone:true
})

export class TranslatePipe implements PipeTransform {


    transform( translateKey: string | undefined, lang: string ): string {
        // Verificar si translateKey es undefined
        if(!translateKey) {
            console.error('translate undefined');
            return '';
        }

        // * Get lang
        const currentLang = lang || localStorage.getItem('lang') || 'es';

        // * Load JSON
        const translations = require(`../../../assets/languaje/${currentLang}.json`); // ../../../assets/languaje/${currentLang}.json`

        // Dividir la clave de traducción en partes para navegar por el objeto JSON
        const keys = translateKey.split('.');

        // Recorrer las claves para obtener la traducción final
        let translation = translations;
        for(const key of keys) {
            if (translation && translation.hasOwnProperty(key)) {
				translation = translation[key];
            } else {
                // * If key does not exisst, return original key
                translation = translateKey;
                break;
            }
        }

        // * Return original key if does not found the traduction
        return translation || translateKey;

    }
}
