/**
*!	 	Componente ButtonWarehouseComponent
*!		  Este componente representa un botón reutilizable que se puede personalizar
*!		  a través de diferentes propiedades. Incluye opciones para mostrar un
*!		  icono, cambiar el estilo del botón y controlar su estado (habilitado/deshabilitado).
*!
*!		 Propiedades:
*!		 	- buttonClass: (string) Clase CSS para aplicar estilos al botón.
*!		 		Valor por defecto: 'btn-sm-primary'.
*!		 	- label: (string) Texto que se mostrará en el botón.
*!		 	- showLabel: (boolean) Indica si se debe mostrar el label.
*!		 		Valor por defecto: true.
*!		 	- icon: (string) Nombre del icono a mostrar en el botón.
*!		 	- colorIcon: (string) Color del icono.
*!		 	- disabled: (boolean) Indica si el botón debe estar deshabilitado.
*!		 		Valor por defecto: false.
*!		 	- isLoader: (boolean) Indica si debe mostrarse un indicador de carga
*!		 		en lugar del label.
*!
*!		 Uso:
*!	 	<app-button-warehouse [label]="'Enviar'" [icon]="'send'" [disabled]="false"></app-button-warehouse>
*!
*!		 Desarrollado por:
*!	 	Alan Cortez
*!
*!		 Fecha:
*!	 	30/10/2024
*/
import { CommonModule, NgIf } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { TranslateService } from 'src/app/core/services/translate/translate.service';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { IconComponent } from '../icon/icon.component';
@Component({
	selector: 'app-button-warehouse',
	standalone: true,
	imports: [
		TranslatePipe,
		NgIf,
		CommonModule,
		IconComponent
	],
	templateUrl: './button-warehouse.component.html',
	styleUrl: './button-warehouse.component.scss'
})
export class ButtonWarehouseComponent implements OnInit {
	public ts = inject( TranslateService );
	@Input() buttonClass: string = 'btn-sm-primary'; // Valor por defecto
  	@Input() label: string = ''; // Texto del label
	@Input() showLabel:boolean=true;
	@Input() icon: string = '';
	@Input() colorIcon:string ='';
	@Input() disabled: boolean = false;
	@Input() isLoader?: boolean;

	ngOnInit(): void {
	}
}
