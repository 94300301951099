import { Injectable, Injector } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LocaleService {
	private localeSubject = new BehaviorSubject<string>('es-ES');
	locale$ = this.localeSubject.asObservable();

	constructor() {}

	changeLocale(locale: string) {
		console.log('Cambiando locale a:', locale);
		this.localeSubject.next(locale);
	}

	get currentLocale(): string {
		return this.localeSubject.getValue();
	}
}
