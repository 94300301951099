import { ApplicationRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocaleService } from './locale.service';

@Injectable({providedIn: 'root'})
export class TranslateService {
	private lang: string = 'es'; // Idioma por defecto
	private langSubject = new BehaviorSubject<string>(this.lang);
	lang$ = this.langSubject.asObservable();
	constructor(private appRef: ApplicationRef, private localeService: LocaleService) {
		const storedLang = localStorage.getItem('lang');
		if (storedLang) {
			this.lang = storedLang;
		} else {
			localStorage.setItem('lang', 'es'); // Si no hay idioma, se establece 'es'
		}
		this.langSubject.next(this.lang);
	}
	get GetLang(): string {
		return this.lang; // Retorna el idioma actual
	}
	// Cargar las traducciones de manera dinámica
	async transform(key: string): Promise<string> {
		try {
			const translations = await import(`../../../../assets/languaje/${this.lang}.json`);
			const keys = key.split('.');
			let translation = translations;

			for (const k of keys) {
			if (translation && translation.hasOwnProperty(k)) {
				translation = translation[k];
			} else {
				return key; // Retorna la clave si no se encuentra la traducción
			}
			}
			return translation;
		} catch (error) {
			console.error('Error al cargar la traducción:', error);
			return key; // En caso de error, se devuelve la clave original
		}
	}
	// Cambiar el idioma y el LOCALE_ID
	changeLang(newLang: string) {
		const lang = newLang === 'es' ? 'es' : 'en'; // Solo 'es' o 'en' son válidos
		localStorage.setItem('lang', lang);
		this.lang = lang;
		this.langSubject.next(lang); // Notifica a los suscriptores sobre el cambio

		// Cambiar el LOCALE_ID a través de LocaleService
		const locale = lang === 'es' ? 'es' : 'en';
		this.localeService.changeLocale(locale); // Cambia el LOCALE_ID

		console.log('locale',locale);
		// Forzar un refresco de la aplicación para reflejar el cambio de idioma
		this.appRef.tick();
	}
}
