// * Angular
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { provideRouter, withViewTransitions } from '@angular/router';
// * Routes
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { NgxQRCodeModule } from 'ngx-qrcode2';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
		routes,
		withViewTransitions(),
    ),
    importProvidersFrom(
		HttpClientModule,
    ), provideAnimationsAsync(), provideAnimationsAsync(),
  ]
};
