import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EncryptDataService } from '../security/encypt-data.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
	providedIn: 'root',
})
export class Interceptor implements HttpInterceptor {
	private token: string;
	constructor(private router: Router, private securityLocalStorage: EncryptDataService, private dialog: MatDialog) { }
	setToken(token: string) {
		this.token = token;
	}
	getToken() {
		if (localStorage.getItem('data') !== null) {
			let data = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')));
			return data.token;
		} else {
			return null;
		}
	}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		console.log(req);
		let headers = new HttpHeaders({'Content-Type': 'application/json'});
		const token = this.getToken();
		if (token) {
			headers = headers.set('token', token);
		}
		const reqClone = req.clone({headers});
		return next.handle(reqClone).pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
	}
	private handleError(error: HttpErrorResponse){
		console.log(error);
		if (error.error instanceof ErrorEvent) {
			console.error('Error del cliente:', error.error.message);
		}
		else {
			this.dialog.closeAll();
			console.error(`Código de error ${error.status}, ` + `mensaje: ${error.message}`);
			if (error.status === 401) {
				this.router.navigate(['/auth/login']);
			}
			else if (error.status === 403) {
				if (!localStorage.getItem('tokenExpiredToastShown')) {
					this.router.navigate(['/auth/login']);
					localStorage.setItem('tokenExpiredToastShown', 'true');
				}
				this.router.navigate(['/auth/login']);
			}
			else if (error.status === 500 && error.error && error.error.message && error.error.message.includes('Clave primaria no válida')) {
				console.error('Error: Clave primaria incorrecta');
			}
		}
		return throwError('Ocurrió un error. Por favor, inténtelo de nuevo más tarde.');
	}
}
