<button [class]="buttonClass" [disabled]="this.disabled">
	@if (isLoader === true) {
        <span class="loader"></span>
    } @else if (isLoader === false || isLoader === undefined) {
		<label *ngIf="showLabel">
			{{ label | translate:ts.GetLang}}
		</label>
		<app-icon [name]="icon" [color]="colorIcon" size="lg">

		</app-icon>
    }
</button>
