import { Component, HostListener, LOCALE_ID, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ThemeService } from './core/services/theme.service';
import { CommonModule } from '@angular/common';
import { AlertsComponentComponent } from './shared/components/returns-portal-components/alerts-component/alerts-component.component';
import { MatCommonModule } from '@angular/material/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './core/interceptors/interceptor.service';
import { TranslateService } from './core/services/translate/translate.service';
import { LocaleService } from './core/services/translate/locale.service';


@Component({
	selector: 'app-root',
	standalone: true,
	imports: [
		CommonModule,
		MatCommonModule,
		CommonModule,
		RouterOutlet,
		AlertsComponentComponent,

	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS, // Proporcionar el interceptor
			useClass: Interceptor, // Usar la clase del interceptor
			multi: true, // Permitir la inyección múltiple de interceptores
		},
		{
			provide: LOCALE_ID,
			useFactory: () => localStorage.getItem('lang') ==='es' ? 'es-ES' : 'en-US'
		}
	],
	templateUrl: './app.component.html',
	styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
	constructor(
		private themeService: ThemeService,
		private translateService: TranslateService,
		private localeService: LocaleService
	) {}

	ngOnInit(): void {
		// this.changeLanguage('es');
	}
	// Método para cambiar el idioma
	changeLanguage(language: string) {
		this.translateService.changeLang(language); // Cambia el idioma
		const locale = language === 'es' ? 'es-ES' : 'en-US';
		this.localeService.changeLocale(locale); // Cambia el LOCALE_ID (internamente)

		// Opción para recargar la página y aplicar completamente el cambio de idioma en la interfaz
		location.reload();
	}
}
