<div class="container-error-403">
	<div class="box-container">
		<div class="box-header-container">
			<div class="box-header-title">
				<div class="box-header">
					<div class="text">
						{{ 'page403.title' | translate:ts.GetLang}}
					</div>
					<div class="subtitle">
						{{ 'page403.subtitle' | translate:ts.GetLang}}
					</div>
				</div>
				<div class="box-footer">
					{{ 'page403.paragraph' | translate:ts.GetLang}}
				</div>
			</div>
			<app-button-warehouse label="buttons.goToMainScreen" buttonClass="btn-md-primary" (click)="tryToLogin()">
			</app-button-warehouse>
		</div>
		<div class="box-footer">
		</div>
	</div>
</div>
