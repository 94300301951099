import { Component, inject } from '@angular/core';
import { ButtonWarehouseComponent } from '../../components/returns-portal-components/button-warehouse/button-warehouse.component';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/translate/localstorage.service';
import { TranslateService } from 'src/app/core/services/translate/translate.service';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
	selector: 'app-page403',
	standalone: true,
	imports: [
		ButtonWarehouseComponent,
		TranslatePipe
	],
	templateUrl: './page403.component.html',
	styleUrl: './page403.component.scss'
})
export class Page403Component {

	public router = inject( Router );
	public localService = inject( LocalStorageService );
	public ts = inject( TranslateService );

	tryToLogin(){
		this.router.navigateByUrl('auth/login')
	}
}
