/**
*!	  Componente IconComponent
*!
*!	  Este componente representa un ícono SVG con opciones personalizables de color, tamaño y grosor de línea.
*!	  Puede utilizar este componente para representar íconos específicos cargados desde un archivo JSON, aplicando estilos personalizados.
*!
*!	  Propiedades de Entrada:
*!	    - `name` (string, requerido): Nombre del ícono que debe cargarse desde el archivo `icons.json`.
*!	    - `color` (string): Define el color principal del ícono. Puede ser un color hex (#RRGGBB) o una variable de CSS (`var(--color-name)`).
*!	    - `size` (string): Define el tamaño del ícono. Opciones disponibles: 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl'.
*!	    - `strokeWidth` (string, predeterminado 'medium'): Grosor del trazo del ícono. Opciones: 'thin', 'regular', 'medium', 'semibold', 'bold', 'extrabold'.
*!	    - `fillColor` (string, opcional): Define el color de relleno del ícono, si está presente.
*!
*!	  Propiedades Internas:
*!	    - `svgIcon`: Contiene el HTML SVG procesado del ícono seleccionado. Este valor se utiliza para mostrar el ícono en la plantilla.
*!	    - `httpClient`: Servicio `HttpClient` utilizado para realizar la solicitud al archivo `icons.json`.
*!	    - `sanitizer`: Servicio `DomSanitizer` utilizado para sanitizar el HTML SVG para su inserción segura.
*!
*!	  Métodos Principales:
*!	    - `ngOnChanges()`: Se ejecuta cuando cambia alguna propiedad de entrada. Carga y procesa el SVG correspondiente al `name` especificado en `icons.json`.
*!	      - Cambia el tamaño, color y aplica otros estilos según las propiedades `color`, `size`, `strokeWidth` y `fillColor`.
*!	      - Aplica un estilo de línea punteada si el nombre del ícono comienza con 'dotted-'.
*!
*!	    - `getStrokeWidth()`: Devuelve el grosor de trazo en píxeles para el ícono, según el valor de `strokeWidth`.
*!	      - Retorna valores numéricos específicos para cada nivel de grosor ('thin' a 'extrabold').
*!
*!	  Plantilla:
*!	    - La plantilla utiliza `[innerHTML]="svgIcon"` para representar el SVG procesado dentro de un contenedor `<span>` flex.
*!
*!	  Ejemplo de Uso:
*!	  ```html
*!	  <app-icon name="home" color="#000000" size="md" strokeWidth="regular"></app-icon>
*!	  ```
*!
*!	  Servicios Utilizados:
*!	    - `HttpClient`: Para obtener los datos del archivo `icons.json`.
*!	    - `DomSanitizer`: Para sanitizar el contenido SVG antes de incrustarlo en el HTML.
*!
*!	  Desarrollado por:
*!	  Alan Cortez
*!
*!	  Fecha:
*!	  30/10/2024
*/
import { HttpClient } from '@angular/common/http';
import { Component, Input, inject, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-icon',
    standalone: true,
    imports: [],
    template: '<span style="display: flex;" [innerHTML]="svgIcon"></span>',
})
export class IconComponent implements OnChanges {
    public httpClient = inject(HttpClient);
    public sanitizer = inject(DomSanitizer);

    @Input() name!: string;
    @Input() color!: string;
    @Input() size!: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl';
    @Input() strokeWidth: 'thin' | 'regular' | 'medium' | 'semibold' | 'bold' | 'extrabold' = 'medium';
    @Input() fillColor: string | undefined;

    public svgIcon: any;

    ngOnChanges(): void {
        if (!this.name) {
            this.svgIcon = '';
            return;
        }
        this.httpClient
            .get(`../../../../../assets/icons/icons.json`, {
                responseType: 'text',
            })
            .subscribe((value: any) => {
                // Get SVG code
                const rawSvg = JSON.parse(value)[this.name.replace('dotted-', '')];

                // Change size
                let processedSvg = rawSvg.toString().replace('<svg ', `<svg style="width: var(--size-icon-${this.size}); height: var(--size-icon-${this.size});" `);

                // Change color
                const color = this.color.startsWith('#') ? this.color : `var(--${this.color})`;
                if (this.name === 'system-tiendanube') {
                    processedSvg = processedSvg.toString().replace(/fill=".*?"/g, `fill="${color}" stroke-width="${this.getStrokeWidth()}"`);
                } else if (!this.name.startsWith('system-')) {
                    if (this.fillColor) {
                        const fillColor = this.fillColor.startsWith('#') ? this.fillColor : `var(--${this.fillColor})`;
                        processedSvg = processedSvg.toString().replace(/stroke=".*?"/g, `stroke="${fillColor}" fill="${fillColor}" stroke-width="${this.getStrokeWidth()}"`);
                    } else {
                        processedSvg = processedSvg.toString().replace(/stroke=".*?"/g, `stroke="${color}" stroke-width="${this.getStrokeWidth()}"`);
                    }
                }

                // Add dotted
                if (this.name.startsWith('dotted-')) {
                    processedSvg = processedSvg.replace('<path ', '<path stroke-dasharray="3 3"');
                }

                this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(processedSvg);
            });
    }

    getStrokeWidth() {
        if (this.strokeWidth === 'thin') {
            return '1.25';
        } else if (this.strokeWidth === 'regular') {
            return '2';
        } else if (this.strokeWidth === 'medium') {
            return '2.25';
        } else if (this.strokeWidth === 'semibold') {
            return '2.50';
        } else if (this.strokeWidth === 'bold') {
            return '2.75';
        } else if (this.strokeWidth === 'extrabold') {
            return '3';
        } else {
            return '1.25';
        }
    }
}
