import { Routes } from '@angular/router';
import { Page404Component } from './shared/pages/page404/page404.component';
import { Page403Component } from './shared/pages/page403/page403.component';
import { Page500Component } from './shared/pages/page500/page500.component';
import { Page503Component } from './shared/pages/page503/page503.component';

export const routes: Routes = [
    {
        path:'auth',
		loadComponent: () =>
			import('../app/modules/auth/auth.component'),
        children: [
            {
                path:'login',
                title:'Login',
                data:{translate:'menu-auth.login'},
                loadComponent: () =>
					import('./modules/auth/pages/login/login.component'),
            },
			{
                path:'products',
                title:'Products',
                data:{translate:'menu-auth.product'},
                loadComponent: () => import('./modules/auth/pages/products/products.component').then(m => m.ProductsComponent), // Usamos `.then` para obtener el componente correcto
            },
			{
                path:'returns',
                title:'Returns',
                data:{translate:'menu-auth.returns'},
                loadComponent: () => import('./modules/auth/pages/return-product-final/return-product-final.component').then(m => m.ReturnProductFinalComponent), // Usamos `.then` para obtener el componente correcto
            },
			{
                path:'cancel',
                title:'Cancel',
                data:{translate:'menu-auth.cancel'},
                loadComponent: () => import('./modules/auth/pages/cancel/cancel.component').then(m => m.CancelComponent), // Usamos `.then` para obtener el componente correcto
            },
			{
                path:'cancel-request',
                title:'CancelRequest',
                data:{translate:'menu-auth.cancelRequest'},
                loadComponent: () => import('./modules/auth/pages/cancel-request/cancel-request.component').then(m => m.CancelRequestComponent), // Usamos `.then` para obtener el componente correcto
            },
			{
                path:'tracking',
                title:'Tracking',
                data:{translate:'menu-auth.tracking'},
                loadComponent: () => import('./modules/auth/pages/tracking/tracking.component').then(m => m.TrackingComponent), // Usamos `.then` para obtener el componente correcto
            },
            { path:'', redirectTo:'login', pathMatch:'full' }
        ]
    },
	{
		path:'403',
		component:Page403Component
	},
    {
        path:'404',
        component:Page404Component
    },
	{
        path:'500',
        component:Page500Component
    },
	{
        path:'503',
        component:Page503Component
    },
    { path:'', redirectTo:'/auth', pathMatch:'full' },
    { path:'**', redirectTo:'/404', pathMatch:'full' }
];
